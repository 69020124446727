import React from 'react';
import ReactDOM from 'react-dom/client';
// @ts-ignore
import { WagmiProvider, createConfig, http } from "wagmi";
// @ts-ignore
import { mainnet, bsc, arbitrum } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import './index.css';
// @ts-ignore
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import App from "./App";

export const config = createConfig(
  // @ts-ignore
  getDefaultConfig({
    // Your dApps chains
    chains: [mainnet, bsc, arbitrum],
    transports: {
      // RPC URL for each chain
      [mainnet.id]: http(
        `https://eth-mainnet.g.alchemy.com/v2/`,
      ),
      // RPC URL for each chain
      [bsc.id]: http(
        `https://bsc-dataseed.binance.org/`
      ),
      // RPC URL for each chain
      [arbitrum.id]: http(
        `https://arb1.arbitrum.io/rpc`
      ),
    },
    // Required API Keys
    walletConnectProjectId: '',
  }),
);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiProvider  config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider theme='soft' customTheme={{
          "--ck-connectbutton-font-size": "18px",
          "--ck-connectbutton-font-weight": 600,
        }}>
          <App />
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
