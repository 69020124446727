import "animate.css"
import "./styles/global.scss"
import "./App.css"
import CryptoSwap from "./components/CryptoSwap";
import logo from "./assets/images/logo.png"
import {ConnectKitButton} from "connectkit";
import {useState} from "react";
import {AnnouncementModal} from "./components/AnnouncementModal";

function App() {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <main>
      <div id="leaves">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div className="header">
        <img src={logo} alt="logo" className="logo"/>
        <h1>Voluma Finance</h1>
        <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
      </div>
      <CryptoSwap/>
      {isOpen && <AnnouncementModal onClose={() => setIsOpen(false)} />}
    </main>
  );
}

export default App;
