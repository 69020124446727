import "./AnnouncementModal.scss"
import {useClickOutside} from "./useClickOutside";

import {useEffect, useRef} from "react"

type Props = {
  onClose: () => void
  text?: string
  header?: string
}

export const AnnouncementModal = ({ onClose, text, header}: Props): JSX.Element => {
  const body = document.body
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
    body.style.overflow = "hidden"
    body.style.overflow = "hidden"
  }, [body.style.overflow])

  const handleClose = () => {
    body.style.overflow = "scroll"
    onClose()
    body.style.overflow = "scroll"
  }

  useClickOutside(wrapperRef, () => {
    handleClose()
  })

  return (
    <div className="announcement-modal-wrapper">
      <div
        ref={wrapperRef}
        className="announcement-modal-content animate__animated animate__zoomIn animate__faster">
        <h2 className="announcement-modal-content-title">{header || 'WARNING!'}</h2>
        <p className="announcement-modal-content-desc">
          Voluma Finance is currently in development mode. <br/> Contact support: <a
            href="mailto:support@voluma.finance">support@voluma.finance</a>
        </p>
        <div className="announcement-modal-content-footer">
          <button onClick={handleClose}>Agree</button>
        </div>
      </div>
    </div>
  )
}
